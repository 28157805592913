import cookieStore from '@paybis/frontend-common-lib/src/services/cookie';

const COOKIE_POPUP_KEY = 'cookie-popup'
const cookieExpiration = new Date().setFullYear(new Date().getFullYear() + 10); // expires in 10 years

export default {
  acceptCookie() {
    cookieStore.set(COOKIE_POPUP_KEY, true, {
      expires: new Date(cookieExpiration),
    });
  },
  isPopupAccepted() {
    return cookieStore.get(COOKIE_POPUP_KEY) !== undefined;
  },
};
