import CookieService from "../services/cookie";

document.addEventListener("DOMContentLoaded", () => {
  initiateCloseCookiePopupHandler();
  initiateBackToTopButtonHandler();
});

/*
 ===========================================================================
     Initiate Back To Top Button for every page
 ===========================================================================
 */
function initiateBackToTopButtonHandler() {
  const backToTopButton = document.getElementById("cex-back-to-top");
  if (backToTopButton === null) {
    return;
  }
  window.addEventListener("scroll", () => {
    const scrollAtTop = document.body.scrollTop === 0;
    backToTopButton.classList.toggle("show", scrollAtTop);
  });

  backToTopButton.addEventListener("click", (event) => {
    event.preventDefault();
    if ("scrollBehavior" in document.documentElement.style) {
      document.body.scrollIntoView({ behavior: "smooth" });
    }
    document.body.scrollIntoView();
  });
}

export const bodyFreeze = function () {
  var body = document.body;
  var outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll";
  outer.style.msOverflowStyle = "scrollbar";
  document.body.appendChild(outer);
  var inner = document.createElement("div");
  outer.appendChild(inner);
  var scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
  outer.parentNode.removeChild(outer);
  body.classList.add("_freeze");
  body.style.paddingRight = scrollbarWidth + "px";
};
export const bodyUnfreeze = function () {
  var body = document.body;
  if (!body.classList.contains("_freeze")) {
    return;
  }
  body.removeAttribute("style");
  body.classList.remove("_freeze");
};

function initiateCloseCookiePopupHandler() {
  const cookiePopup = document.getElementById("cookie-popup-block");

  if (cookiePopup === null) {
    return;
  }

  cookiePopup
    .querySelector(".btn-primary")
    .addEventListener("click", (event) => {
      event.preventDefault();
      CookieService.acceptCookie();
      cookiePopup.style.display = "none";
      if (typeof LiveAgent === "undefined") {
        return;
      }
  
      const containerElement = document.getElementById("chatButton");
      const currentTestId = containerElement.getAttribute("data-testid");
  
      const LiveAgentButton = LiveAgent.createButton(
        LiveAgentButtonCode,
        containerElement
      );
  
      if (currentTestId) {
        const buttonElement = document.getElementById(LiveAgentButton.elementId);
        buttonElement.setAttribute("data-testid", currentTestId);
      }
    });
}
